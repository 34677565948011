import React from 'react';

import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <div>
                Made with ❤️ and 🥃 in Boston.
            </div>
        </footer>
    );
}

export default Footer;