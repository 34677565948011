import React from 'react'

import Header from './Header';
import Footer from './Footer';

class Layout extends React.Component {
  render() {
    const { title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`


    return (
      <div>
        <Header title={title}/>
        <main>
          {children}
        </main>
        <Footer />
      </div>
    )
  }
}

export default Layout;
