import React from 'react';
import { Link } from 'gatsby'

import './Header.scss';

const Header = (props) => {
    return (
        <header>
            <nav>
                <Link
                    to={'/'}>
                    <h1>Amy Fairbrother | {props.title}</h1>
                </Link>
            </nav>
        </header>
    );
}

export default Header;